module.exports = {
    name: 'WeRise',
    logo: 'https://img.genflow.com/werise/checkout/weriselogo.png',
    vendorUUID: 'c31f2558-e73a-49eb-8120-c3d388bfdc1d',
    metaData: {
        title: 'WeRise',
        description: 'Welcome to WeRise.',
        author: 'GENFLOW',
        url: 'https://www.werise.xyz/',
        image: 'https://img.genflow.com/weirse/checkout/weriselogo.png',
    },
    theme: {
        colors: {
            primary: '#e1d5cb',
            text: '#697386',
            ctaText: '#000',
        },
    },
    analytics: {
        gtag: 'G-PH2Z93MM4X',
        facebookPixel: '1277711849563382',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://werise.xyz/terms-conditions',
        privacy: 'https://werise.xyz/privacy-policy',
        support: 'https://support.werise.xyz/hc/en-us',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/us/app/we-rise-app/id6474153357',
        android: 'https://play.google.com/store/apps/details?id=com.weriseapp.app',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
            // registerImage: 'https://d2ztsos3nab125.cloudfront.net/001dcbaf-4985-411f-a0d5-f2c4a83a6d00/originals/checoutImage.png',
            registerInstructions: [
                'Sign up below with the same email you used for Crush It.',
                'This will migrate your membership automatically.',
                'Once completed, download the WeRise app and login with the account you made.',
                'Enjoy the new app!'
            ]
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            coupon: 'uK5QBqiF',
            label: '50% OFF',
        }, 
        {
            title: '3 Months',
            product: '3month',
            coupon: 'oRkjKxBp',
            label: '2 MONTHS FREE!'
        }, 
        // {
        //     title: '6 Months',
        //     product: '6month',
        // }, 
        {
            title: '12 Months',
            product: '12month',
            // coupon: 'Cf0ruuZB',
            // label: '30% OFF'
        },
    ],
    offers: [
        {
            id: 'pdf',
            products: [
                {
                    title: '1 Month',
                    product: '1month',
                    coupon: '0JHvGAZq',
                    label: '50% OFF',
                }
            ]
        },
    ],
    currencies: {
        available: [
            {
                code: 'GBP',
                icon: '🇬🇧',
            },
            {
                code: 'USD',
                icon: '🇺🇸',
            },
            {
                code: 'EUR',
                icon: '🇪🇺',
            },
            {
                code: 'AUD',
                icon: '🇦🇺',
            },
            {
                code: 'CAD',
                icon: '🇨🇦',
            },
            {
                code: 'MXN',
                icon: '🇲🇽',
            },
        ],
        default: 'USD',
    },
};
